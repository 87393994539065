// import logo from "./logo.svg";
import React, { useEffect, useReducer } from "react";
import "./App.css";
import Header from "./components/header";
import Zoom from "react-reveal/Zoom";
import { Fade } from "react-reveal";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const handleCopy = () => {
  const walletAddress = '0x60F702A9e3878666E838b0a56fb3DDfC1067C2c0'; // Replace with your actual wallet address
  navigator.clipboard.writeText(walletAddress)
    .then(() => {
      // Show toast notification
      toast.success('Address copied!', {
        position: 'top-center',
        autoClose: 2000, // Close after 2 seconds
        hideProgressBar: true,
      });
    })
    .catch((error) => {
      // Handle error
      console.error('Failed to copy wallet address:', error);
    });
};

function numberWithCommas(x) {
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function App() {

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_DATA':
        console.log(action.payload)
        const appBurnAmount = action.payload.burnData ? action.payload.burnData.burnAmount : 0
        const totalSupply = action.payload.totalSupply.totalSupply
        // const { burnAmount, totalSupply } = action.payload;
        const totalBurned = (1000000000 - totalSupply).toFixed(2);
        const totalBurnedPerc = (totalBurned / 1000000000).toFixed(8);
        const anonBurned = numberWithCommas((totalBurned - appBurnAmount).toFixed(2).toString());
        const amountTotalBurned = numberWithCommas(totalBurned.toString())
        const burnAmount = numberWithCommas(appBurnAmount.toFixed(2).toString())

        return {
          ...state,
          burnAmount,
          amountTotalBurned,
          totalBurnedPerc,
          anonBurned
        };
      default:
        return state;
    }
  };

  const initialState = {
    burnAmount: 0.00,
    amountTotalBurned: 0.00,
    totalBurnedPerc: 0.00000000,
    anonBurned: 0.00
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchChainBurnData = async () => {
      const address = "0x2CC02f0Bb68CC19E4ceD92914A56B594340073Fe"; // Replace with the specific address you want to fetch the burnAmount for

      try {
        const response = await fetch(`https://api.maxibarsik.com:443/api/burnDataMaxiB?address=${address}`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();

        dispatch({ type: 'SET_DATA', payload: data });
      } catch (error) {
        console.error(error);
      }
    };

    // Initial fetch when the component mounts
    fetchChainBurnData();

    // Polling interval: once every hour (in milliseconds)
    const pollingInterval = 3600000; // 1 hour = 3600 seconds * 1000 milliseconds 3600000

    // Set up the interval to fetch data periodically
    const intervalIdAppBurn = setInterval(fetchChainBurnData, pollingInterval);

    // Clean up function to clear the interval when the component unmounts
    return () => clearInterval(intervalIdAppBurn);
  }, []);

  const cardsArray = [
    {
      img: "/Logos.svg",
      title: "MaxiB Whale",
      link: "https://whale.maxibarsik.com",
    },
    {
      img: "/Logos.png",
      title: "Soon",
    },
    {
      img: "/Logo-2.svg",
      title: "Soon",
    },
  ];
  const cardsArray1 = [
    {
      img: "/Logo-2.svg",
      title: "Soon",
    },
    {
      img: "/Logo-4.png",
      title: "Soon",
    },
    {
      img: "/Logo-4.png",

      title: "Soon",
    },
  ];
  const cardsArray2 = [
    {
      img: "/Logo-4.png",

      title: "Soon",
    },
  ];
  return (
    <div className="bg-[#0E0D0C] h-[100vh] pb-[100px]">
      <ToastContainer />
      <div className="text-white  pb-[43px] my-bg1 px-[20px]">
        <Header />
        <Zoom>
          <p className="md:text-[100px] text-[50px] md:leading-[100px] leading-[50px] sm:mt-0 mt-[20px]  text-[#FFFAF5] font1 uppercase text-center shadows">
            Burn them <br className="sm:hidden" /> all!
          </p>
        </Zoom>

        <div className="max-w-[840px] w-[100%] bg-black m-auto sm:mt-[40px] mt-[20px]  pb-[60px] rounded-[10px] ">
          <Zoom>
            <img
              src="/photo.png"
              alt=""
              className="m-auto w-[100%]  rounded-t-[10px]"
            />
          </Zoom>
          <Fade>
            <p className="font2 md:text-[24px] text-[18px] text-[#FFFAF5] text-center md:mt-[60px] mt-[30px]">
              Number of <span className="text-bold">$MaxiB</span> burnt so far
            </p>
          </Fade>
          <Zoom>
            <p className="font1 md:text-[140px] text-[60px] md:leading-[140px] leading-[60px] shadows md:mt-0 mt-[10px]">
              {state.amountTotalBurned}
            </p>
          </Zoom>
          <div className="flex items-center justify-center mt-[10px]">
            <p className="font2 md:text-[24px] text-[18px] text-center">{state.totalBurnedPerc}%</p>
            <p className="font2 md:text-[24px] text-[18px] sm:ml-[10px] ml-[5px]">
              of the entire <br /> circulating supply
            </p>
          </div>
          <p className="text-[60px] font1 uppercase text-center md:mt-[60px] mt-[30px] text-[#FFFAF5]">
            Breakdown
          </p>

          <div className="grid grid-cols-2 sm:mt-[43px] mt-[33px]">
            <div>
              <p className="font2 text-[#795A49] text-center md:text-[24px] text-[18px]">
                Name of app
              </p>
            </div>
            <div>
              <p className="font2 text-[#795A49] text-center md:text-[24px] text-[18px]">
                Number of MaxiB burnt
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2  items-center mt-[10px]">
            <div>
              <p className="font2 md:text-[24px] text-[18px] text-[#FFFAF5]   text-center">
                MaxiB Whale
              </p>
            </div>
            <div>
              <div className=" md:flex items-center justify-center sm:leading-[70px] leading-8">
                <p className="font1 shadows md:text-[50px] text-[32px]">
                  {state.burnAmount}
                </p>
                <p className="md:text-[24px] text-[18px] text-[#FFFAF5] text-center md:ml-5 text-[700] font2">
                  $MaxiB
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2  items-center mt-[10px]">
            <div>
              <p className="font2 md:text-[24px] text-[18px] text-[#FFFAF5]   text-center">
                Soon
              </p>
            </div>
            <div>
              <p className="font1 shadows  text-[32px] sm:leading-[70px] leading-8">
                <div className=" md:flex items-center justify-center">
                  <p className="font1 shadows md:text-[50px] text-[32px]">0</p>
                  <p className="md:text-[24px] text-[18px] text-[#FFFAF5] text-center md:ml-5 text-[700] font2">
                    $MaxiB
                  </p>
                </div>
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2  items-center mt-[10px]">
            <div>
              <p className="font2 md:text-[24px] text-[18px] text-[#FFFAF5]   text-center">
                Soon
              </p>
            </div>
            <div>
              <p className="font1 shadows  text-[32px] sm:leading-[70px] leading-8">
                <div className=" md:flex items-center justify-center">
                  <p className="font1 shadows md:text-[50px] text-[32px]">0</p>
                  <p className="md:text-[24px] text-[18px] text-[#FFFAF5] text-center md:ml-5 text-[700] font2">
                    $MaxiB
                  </p>
                </div>
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2  items-center mt-[10px]">
            <div>
              <p className="font2 md:text-[24px] text-[18px] text-[#FFFAF5]   text-center">
                Soon
              </p>
            </div>
            <div>
              <p className="font1 shadows  text-[32px] sm:leading-[70px] leading-8">
                <div className=" md:flex items-center justify-center">
                  <p className="font1 shadows md:text-[50px] text-[32px]">0</p>
                  <p className="md:text-[24px] text-[18px] text-[#FFFAF5] text-center md:ml-5 text-[700] font2">
                    $MaxiB
                  </p>
                </div>
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2  items-center mt-[10px]">
            <div>
              <p className="font2 md:text-[24px] text-[18px] text-[#FFFAF5]   text-center">
                Soon
              </p>
            </div>
            <div>
              <p className="font1 shadows  text-[32px] sm:leading-[70px] leading-8">
                <div className=" md:flex items-center justify-center">
                  <p className="font1 shadows md:text-[50px] text-[32px]">0</p>
                  <p className="md:text-[24px] text-[18px] text-[#FFFAF5] text-center md:ml-5 text-[700] font2">
                    $MaxiB
                  </p>
                </div>
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2  items-center mt-[10px]">
            <div>
              <p className="font2 md:text-[24px] text-[18px] text-[#FFFAF5]   text-center">
                Anon
              </p>
            </div>
            <div>
              <p className="font1 shadows  text-[32px] sm:leading-[70px] leading-8">
                <div className=" md:flex items-center justify-center">
                  <p className="font1 shadows md:text-[50px] text-[32px]">{state.anonBurned}</p>
                  <p className="md:text-[24px] text-[18px] text-[#FFFAF5] text-center md:ml-5 text-[700] font2">
                    $MaxiB
                  </p>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#151311] md:py-[80px] py-[60px] md:px-0 px-5">
        <Fade top>
          <p className="font2 md:text-[56px] text-[36px] text-center text-white">
            Keep burning them till we reach
          </p>
        </Fade>
        <Fade bottom>
          <p className="md:text-[90px] text-[80px] shadows text-center md:mt-0 mt-[10px] font1 text-white">
            {" "}
            $5B MC
          </p>
        </Fade>
      </div>
      <div className="bg-[#0E0D0C] md:py-[80px] py-[60px] md:px-0 px-[20px] ">
        <Fade top>
          <p className="font1 md:text-[86px] text-[56px] md:leading-[86px] leading-[56px] text-[#FFFAF5] text-center">
            How to burn <br className="sm:hidden" /> more?
          </p>
        </Fade>
        <Fade bottom>
          <p className="text-[#FFFAF5] text-center font2 md:text-[32px] text-[26px] mt-[10px]">
            Play, Win,  Burn
          </p>
        </Fade>
        <Fade bottom>
          <p className="font2 text-white md:text-[36px] text-[26px] text-center md:mt-[70px] mt-[30px] sm:px-0 sm:max-w-fit max-w-[200px] m-auto">
            Play any of the Maxi Barsik games
          </p>
        </Fade>
        <div className="max-w-[1090px] w-full m-auto md:px-[35px] grid md:grid-cols-3 grid-cols-2 gap-7 mt-[30px]">
          {cardsArray.map((item, index) => (
            <a href={item?.link} target="_blank" rel="noopener noreferrer">
              <div
                key={index}
                className={`${index === 2 && "sm:block hidden"} ${index === 0 && "border-[#F78113] bg-transparent"
                  } cursor-pointer m-auto md:px-[60px] sm:h-auto h-[250px]  px-[20px] py-[41px] w-full sm:w-[calc(50% - 14px)] lg:w-[calc(33.33% - 14px)]  bg-[#1F1D1B] group border-[#795A49]  border-2 rounded-[10px]`}
              >
                {/* <div className="w-[100px] h-[100px] m-auto rounded-full flex justify-center items-center bg-gradient-to-br from-gray-400 via-gray-600 to-gray-800 group-hover:from-yellow-400 group-hover:via-purple-600 group-hover:to-indigo-900"> */}

                <div className="w-[100px] h-[100px] m-auto rounded-full flex justify-center items-center ">
                  <img src={item?.img} alt="" />
                </div>

                <p
                  className={`text-[20px] font2 ${index === 0 ? "text-white" : "text-[#795A49]"
                    }  text-center mt-[20px]`}
                >
                  {item?.title}
                </p>
              </div>
            </a>
          ))}
        </div>
        <div className="max-w-[730px] w-full m-auto justify-center md:px-[35px] grid md:grid-cols-2 grid-cols-2 gap-7 mt-[30px]">
          {cardsArray1.map((item, index) => (
            <div
              key={index}
              className={`${index === 0 && "sm:hidden"}  ${index === 1 && "sm:block hidden "
                } cursor-pointer m-auto md:px-[60px] px-[20px] py-[41px] w-full sm:w-[calc(50% - 14px)] lg:w-[calc(33.33% - 14px)]  bg-[#1F1D1B]  group border-[#795A49]  border-2 rounded-[10px]`}
            >
              <div className="w-[100px] h-[100px] m-auto rounded-full flex justify-center items-center">
                <img src={item?.img} alt="" />
              </div>
              <p
                className={`text-[20px] font2  text-center mt-[20px] text-[#795A49]`}
              >
                {item?.title}
              </p>
            </div>
          ))}
        </div>
        <div className="max-w-[730px] w-full m-auto justify-center md:px-[35px] px-[85px] grid  grid-cols-1  gap-7 mt-[30px]">
          {cardsArray2.map((item, index) => (
            <div
              key={index}
              className={`sm:hidden  cursor-pointer m-auto md:px-[60px] px-[20px]  py-[41px] w-full sm:w-[calc(50% - 14px)] lg:w-[calc(33.33% - 14px)]  bg-[#1F1D1B]  group border-[#795A49]  border-2 rounded-[10px]`}
            >
              <div className="w-[100px] h-[100px] m-auto rounded-full flex justify-center items-center">
                <img src={item?.img} alt="" />
              </div>
              <p className="text-[20px] font2 text-[#795A49] text-center mt-[20px]">
                {item?.title}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="bg-[#151311] md:py-[80px] py-[60px] md:px-0 px-[20px] ">
        <Fade top>
          <p className="md:text-[90px] text-[50px] text-[#FFFAF5] shadows text-center font1">
            Don’t have $MaxiB?
          </p>
        </Fade>
        <Zoom>
          <div className="grid grid-cols-12  max-w-[752px] m-auto mt-[40px]">
            <div className=" md:col-span-4 col-span-8">
              <p className="text-[#FFFAF5] text-[20px] font2">Copy the CA:</p>
              <p className="font3 text-[#FFFAF5] break-all mt-[20px] sm:text-[17.5px] text-[22px]">
                0x60F702A9e3878666E838b0a56fb3DDfC1067C2c0
              </p>
            </div>
            <div className=" col-span-4">
              <div className="w-[76px] h-[76px] bg-[#FDBE23]  flex justify-center items-center rounded-[10px] mt-[30px] ml-5 cursor-pointer" onClick={handleCopy}>
                <img src="/Vector (3).svg" alt="" />
              </div>
            </div>
            <div className=" md:col-span-4 col-span-12 md:mt-0 mt-[30px]">
              <p className="text-[#FFFAF5] text-[20px] font2">Buy it here:</p>
              <a href="https://hermes.maiadao.io/#/swap?outputCurrency=0x60F702A9e3878666E838b0a56fb3DDfC1067C2c0" target="_blank" rel="noopener noreferrer">
                <div className="md:w-[208px] w-[100%] h-[72px] bg-[#FDBE23] rounded-[15px] flex justify-center items-center mt-[10px] cursor-pointer">
                  <p className="text-[#7A1300] text-[20px] font-bold font2">
                    Dex
                  </p>
                  <img src="Icons 36px.svg" alt="" className="ml-1" />
                </div>
              </a>
            </div>
          </div>
        </Zoom>
      </div>
      <div className="footer bg-black">
        <div className="md:py-[80px] py-[60px] bg-no-repeat sm:bg-contain bg-cover sm:bg-center  my-bg">
          <Fade top>
            <p className="text-[#FFFAF5] md:text-[86px] text-[56px] shadows text-center font1">
              Follow us
            </p>
          </Fade>
          <Fade bottom>
            <div className="w-[270px] m-auto flex justify-between items-center cursor-pointer">
              {/* Telegram icon */}
              <a href="https://t.me/MaxiBarsik" target="_blank" rel="noopener noreferrer">
                <div className="w-[70px] h-[70px] rounded-full border  border-[#F78113]  flex justify-center items-center ">
                  <img src="/Vector-4.svg" alt="" />
                </div>
              </a>
              {/* Landing Page icon */}
              <a href="https://maxibarsik.com/" target="_blank" rel="noopener noreferrer">
                <div className="w-[70px] h-[70px] rounded-full  bg-[#05D2FF]   flex justify-center items-center cursor-pointer">
                  <img src="/Group-1.svg" alt="" />
                </div>
              </a>
              {/* Twitter icon */}
              <a href="https://twitter.com/MaxiBarsik" target="_blank" rel="noopener noreferrer">
                <div className="w-[70px] h-[70px] rounded-full border  border-[#F78113]  flex justify-center items-center cursor-pointer">
                  <img src="/Vector (5).svg" alt="" />
                </div>
              </a>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default App;
