import React from "react";

export default function Header() {
  return (

    <div className="md:px-[140px]  md:py-[10px] py-[20px] flex justify-between items-center">
      <img src="/Main Logo.svg" alt="" className="h-[80xp] w-[80px]" />
      <a href="https://hermes.maiadao.io/#/swap?outputCurrency=0x60F702A9e3878666E838b0a56fb3DDfC1067C2c0" target="_blank" rel="noopener noreferrer">
        <div className="font3 uppercase bg-[#FDBE23]  md:w-[253px] w-[193px] md:h-[64px] h-[56px] rounded-[15px] border-2 border-[#F78113] font-bold text-[20px] text-[#7A1300]  flex justify-center items-center cursor-pointer">
          buy $MaxiB
        </div>
      </a>
    </div >

  );
}
